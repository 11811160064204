import {
  Text,
  Stack,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import { MoreInformationHeader } from "./academy-more-information-header";

export function AcademyNeeds() {
  return (
    <Box>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 8, md: 10 }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4} marginTop={{ base: "5%", md: "4%" }}>
          <Text>
            <b>
              Ofertas de empleo confusas, procesos eternos, pruebas técnicas que
              no sirven para nada, hiring managers desconectados del equipo de
              selección…
            </b>{" "}
            ¿Te suena? Seguro que en tu empresa estáis sufriendo alguno de estos
            errores tan comunes en la búsqueda de talento tech. Yo también los
            he vivido, y he aprendido a solucionarlos.
          </Text>
          <Text>
            Estos errores no solo te cuestan candidatos valiosos, sino que te
            están haciendo perder tiempo, eficiencia… y sí, <b>MUCHA PASTA</b>.
            Cada día que no contratas bien, el coste se dispara.
          </Text>
          <Text>
            Por eso, he creado una formación basada en más de 20 años de
            aciertos y meteduras de pata contratando perfiles tech. Te enseñaré,
            con ejemplos reales,{" "}
            <b>
              cómo diseñar procesos más ágiles, redactar ofertas irresistibles y
              conectar de verdad con el talento que tu equipo necesita.
            </b>
          </Text>
        </Stack>
        <MoreInformationHeader />
      
      </SimpleGrid>
    </Box>
  );
}
