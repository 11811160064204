import {
  Heading,
  Text,
  Stack,
  SimpleGrid,
  Image,
  Card,
  CardBody,
  Box,
} from "@chakra-ui/react";

export function WhereITalk() {
  return (
    <Box className="my-history-box" marginTop={{ base: "0vw", md: "1vw" }}>
      &nbsp;
      <Heading
        className="where-heading"
        marginLeft={{ base: "5%", md: "5%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "10vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Dónde he contado mi película
        <span style={{ color: "#04F06A" }}>?</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 4 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "220px", md: "220px" }}
        marginLeft={{ base: "5%", md: "5%" }}
        marginRight={{ base: "5%", md: "5%" }}
      >
        <Stack spacing={4}>
          <a
            href="https://www.youtube.com/watch?v=uhqIqgm1lnM"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="/logo-fredcon.png"
                  alt="Fredcon"
                  rounded={6}
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Evitando problemas de equilibrio salarial!
                  </Heading>
                  <Text>
                    En esta charla desmonté mitos sobre salarios, mostré cómo
                    los desequilibrios salariales destrozan equipos y compartí
                    estrategias para evitar que tu política de compensación sea
                    el caos que nadie quiere admitir.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://www.youtube.com/watch?v=BaS7J5mX2oc"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="/logo-engineering-managers.png"
                  alt="Engineering Managers Talk"
                  rounded={6}
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Charlando con Engineering Managers
                  </Heading>
                  <Text>
                    Fue un autentico placer poder moderar una charla sobre el
                    rol del Engineering Manager entre 4 personas que llevan
                    ejerciendo mucho tiempo dicho rol del que tanto se habla. Un
                    buen rato con Bea (Factorial), Jaime Muñoz (Marketgoo),
                    Javier de Arcos (Celonis) y Felix López.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://www.youtube.com/watch?v=7rAu-747VV8"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="/logo-escalando-equipos.png"
                  alt="Escalando equipos."
                  rounded={6}
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Escalando un equipo técnico con AIDA - A Manfred
                    VideoPodcast #01
                  </Heading>
                  <Text>
                    En AIDA han diseñado un proceso de contratación que les
                    permite entrevistar el talento que necesitan, tomar la mejor
                    decisión e incorporar a los nuevos fichajes a la compañía de
                    manera ágil y funcional... Pero, ¿cómo lo han hecho?
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://salmorejo.tech/2024/"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <center>
                  <Image
                    className="image-events"
                    src="/logo-salmorejo.png"
                    alt="Salmorejo Tech 2024"
                    borderRadius="6"
                  />
                </center>
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Taller - Entrevistas técnicas, pur qué?
                  </Heading>
                  <Text>
                    Las entrevistas técnicas actuales, similares a oposiciones,
                    no reflejan las cualidades reales de los candidatos.
                    Resolver problemas triviales no mide habilidades prácticas y
                    perjudica tanto a empresas como a candidatos. Es hora de
                    debatir y mejorar este modelo en el ámbito tecnológico.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://www.tarugoconf.com/"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="/logo-tarugo.png"
                  alt="Tarugoconf 2022"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Taller: Cómo atraer talento técnico sin tocar LinkedIn ni
                    con un palo ❤️
                  </Heading>
                  <Text>
                    La atracción y retención de talento se ha convertido en una
                    de las principales retos de la industria informática y así
                    seguirá siendo durante mucho, MUCHO tiempo.
                  </Text>
                  <Text>
                    Métricas, buenas prácticas, marketing para developers,
                    procesos, gestión de calidad, automatización,
                    tecnificación... todo lo necesario para poder trabajar en
                    recruiting hoy en día.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://www.morcillaconf.es/"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="/logo-morcillaconf.png"
                  alt="Morcillaconf Logo"
                  rounded={6}
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Charla: ¿Qué buscan las empresas para puestos Junior, MID y
                    Senior?
                  </Heading>
                  <Text>
                    Siempre se ha creado mucho debate sobre que cualidades a de
                    tener un perfil dependiendo de la experiencia. ¿Cuándo se
                    considera a una persona Senior? ¿Qué es un perfil Junior?
                    Pues bien, os contaré mi punto de vista y que buscan las
                    empresas en este tipo de roles.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://2023.commit-conf.com/"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="/logo-commitconf.png"
                  alt="Commitconf logo"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Talent Fishbowl! Recruiters & Developers
                  </Heading>
                  <Text>
                    Juntamos en un mismo espacio a desarrolladores y recruiters
                    para debatir cómo se capta, gestiona y retiene el talento en
                    IT en la actualidad, explorar nuevos caminos y proponer
                    mejoras en un debate moderado y dinamizado por el awesómico
                    tándem formado por Marina Ramos y Leonardo Poza.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://www.codemotion.com/"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="/logo-codemotion.png"
                  alt="Codemotion logo"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Charla: De Programador a Recruiter, por qué pasarse al lado
                    oscuro
                  </Heading>
                  <Text>
                    Recruiters: esas figuras encorbatadas que suelen ser la
                    pesadilla de cualquier programador con LinkedIn, víctimas
                    del eterno spam. Sí, nosotros también lo vivimos en carne
                    propia, rechazando una y otra vez propuestas que poco o nada
                    tenían que ver con nuestras habilidades, intereses o
                    ambiciones.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a
            href="https://www.rootedcon.com/inicio/"
            target={"_blank"}
            rel="noreferrer"
          >
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="/logo-rootedconf.png"
                  alt="Rootedcon logo"
                  rounded={6}
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Charla: De Programador a Recruiter, por qué pasarse al lado
                    oscuro
                  </Heading>
                  <Text>
                    Recruiters: esas figuras encorbatadas que suelen ser la
                    pesadilla de cualquier programador con LinkedIn, víctimas
                    del eterno spam. Sí, nosotros también lo vivimos en carne
                    propia, rechazando una y otra vez propuestas que poco o nada
                    tenían que ver con nuestras habilidades, intereses o
                    ambiciones.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
        <Stack spacing={4}>
          <a href="https://miduconf.com/" target={"_blank"} rel="noreferrer">
            <Card
              maxW="sm"
              minH={"600px"}
              boxShadow={"xl"}
              className="card-zoomer"
              borderRadius="0"
              rounded={"xl"}
            >
              <CardBody>
                <Image
                  className="image-events"
                  src="/logo-miduconf.png"
                  alt="Miduconf"
                  rounded={6}
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    Charla: Dale caña a tu CV para ser contratado
                  </Heading>
                  <Text>
                    Tips and tricks para crear un CV cañero y tener más
                    oportunidades de ser contratado. Siempre hay que poner foco
                    en cual es el próximo reto que se quiere tener y enfocar tu
                    CV hacia puesto de ese tipo. Una charla que vieron mas de
                    13K asistentes. 🤘
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </a>
        </Stack>
      </SimpleGrid>
    </Box>
  );
}
