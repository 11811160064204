import {
  Heading,
  Card,
  Text,
  CardBody,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { Fragment } from "react";

export function IndividualFormatWeeks() {
  return (
    <Fragment>
      <Heading
          className="what-i-do-heading"
          marginBottom={{ base: "15vw", md: "5vw" }}
          marginTop={{ base: "15vw", md: "5vw" }}
          fontSize={{ base: "8vh", md: "12vh" }}
          lineHeight={{ base: "80%", md: "100%" }}
        >
          Semana a semana<span style={{ color: "#C9E500" }}>.</span>
        </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        gap="15px"
        marginBottom={{ base: "5vw", md: "3vw" }}
      >
        
        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack spacing="3">
              <Heading size="md" className="benefits-heading-weeks">
                Primera sesión:
              </Heading>
              <Text className="week-details">Lunes 05 de Mayo: 16:00 - 18:30</Text>
              <hr class="dotted"></hr>
              <Text>01. Introducción al reclutamiento tecnológico.</Text>
              <Text>02. Actores en un proceso de selección.</Text>
              <Text>03. Definición del perfil y alineación del equipo.</Text>
            </Stack>
          </CardBody>
        </Card>

        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack spacing="3">
            <Heading size="md" className="benefits-heading-weeks">
                Segunda sesión:
              </Heading>
              <Text className="week-details">Lunes 12 de Mayo: 16:00 - 18:30</Text>
              <hr class="dotted"></hr>
              <Text>04. ¡Redactando una oferta de empleo con sentido!</Text>
              <Text>05. <span style={{ color: "#f10965", fontWeight: "bold" }}>Sesión con invitados:</span> La opinion y experiencia de candidatos/as.</Text>
            </Stack>
          </CardBody>
        </Card>

        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack spacing="3">
            <Heading size="md" className="benefits-heading-weeks">
                Tercera sesión:
              </Heading>
              <Text className="week-details">Lunes 19 de Mayo: 16:00 - 18:30</Text>
              <hr class="dotted"></hr>
              <Text>05. Estrategias para atraer talento Tech.</Text>
              <Text>06. Evaluación de candidatos: Cómo hacerlo bien.</Text>
              <Text>07. Automatización y herramientas para el reclutamiento ágil.</Text>
            </Stack>
          </CardBody>
        </Card>

        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack spacing="3">
            <Heading size="md" className="benefits-heading-weeks">
                Cuarta sesión:
              </Heading>
              <Text className="week-details">Lunes 26 de Mayo: 16:00 - 18:30</Text>
              <hr class="dotted"></hr>
              <Text>08. Medición y optimización del proceso de selección.</Text>
              <Text>09. Automatizando la Evaluación del Proceso de Selección.</Text>
              <Text>10. <span style={{ color: "#f10965", fontWeight: "bold" }}>Sesión con invitados:</span> Empresas que han contratado con éxito.</Text>
            </Stack>
          </CardBody>
        </Card>
      </SimpleGrid>
    </Fragment>
  );
}
