import {
  Heading,
  Text,
  Stack,
  SimpleGrid,
  VStack,
  Card,
  CardBody,
  Button,
} from "@chakra-ui/react";

import { Fragment } from "react";

export function AcademyWhatTheFaq() {

  return (
    <Fragment>


      <SimpleGrid
      columns={{ base: 1, md: 3 }}
      spacing={{ base: 8, md: 10 }}
      marginBottom={{ base: "100px", md: "100px" }}
      marginLeft={{ base: "5%", md: "5%" }}
      marginRight={{ base: "5%", md: "5%" }}
      marginTop={{ base: "5%", md: "10%" }}
    >
      <Stack spacing={4}>
        <Heading
          className="latest-post-heading"
          marginBottom={{ base: "15vw", md: "5vw" }}
          marginTop={{ base: "-20vw", md: "5vw" }}
          fontSize={{ base: "10vh", md: "12vh" }}
          lineHeight={{ base: "80%", md: "100%" }}
        >
          What the <br></br>FAQ<span style={{ color: "#C9E500" }}>?</span>
        </Heading>
      </Stack>
      <VStack spacing={4} align="stretch">
        <Card
          borderRadius="0"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <Stack>
            <CardBody>
              <Heading className="benefits-heading">
                ¿Se puede realizar en otros horarios?
              </Heading>
              <Text marginTop={{ base: "1vw", md: "1vw" }}>
                Sí, claro. Si no te vienen bien,
                escríbeme y haré todo lo posible para organizar una edición en
                otros horarios.
              </Text>
            </CardBody>
          </Stack>
        </Card>

        <Card
          borderRadius="0"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <Stack>
            <CardBody>
              <Heading className="benefits-heading">
                ¿Puedo organizarlo de forma interna para toda mi empresa?
              </Heading>
              <Text marginTop={{ base: "1vw", md: "1vw" }}>
                Por supuesto. Esta formación será altamente beneficiosa para
                toda la empresa, ya que permite alinear a todos los equipos
                involucrados: desde el CTO y el Hiring Manager, hasta el equipo
                técnico y el de People. Si necesitas un presupuesto
                personalizado, no dudes en escribirme a{" "}
                <a href="mailto:yo@leonardopoza.com"><b>yo@leonardopoza.com</b></a>.
              </Text>
            </CardBody>
          </Stack>
        </Card>
      </VStack>
      <VStack
        spacing={4}
        align="stretch"
        marginTop={{ base: "0%", md: "-20%" }}
      >
        <Card
          borderRadius="0"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <Stack>
            <CardBody>
              <Heading className="benefits-heading">
                ¿Es una formación online o presecial?
              </Heading>
              <Text marginTop={{ base: "1vw", md: "1vw" }}>
                Está diseñada para ser 100% online. Sin embargo, te garantizamos
                una conexión estable, así como una excelente calidad de imagen y
                sonido, para que tu experiencia sea lo más fluida y profesional
                posible.
              </Text>
            </CardBody>
          </Stack>
        </Card>
        <Card
          borderRadius="0"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <Stack>
            <CardBody>
              <Heading className="benefits-heading">
                ¿Las sesiones serán grabadas?
              </Heading>
              <Text marginTop={{ base: "1vw", md: "1vw" }}>
                No, las sesiones no se grabarán y tampoco está permitido que los
                asistentes las graben bajo ningún concepto. Quiero garantizar un
                ambiente de aprendizaje exclusivo y dinámico, por lo que los
                contenidos y las interacciones quedarán disponibles en
                materiales entregados al finalizar la formación, cubriendo todos
                los temas tratados.
              </Text>
            </CardBody>
          </Stack>
        </Card>
        <Card
          borderRadius="0"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <Stack className="card-dossier">
            <CardBody>
              <Heading className="benefits-heading">
                Si te quedan dudas...
              </Heading>
              <Text marginTop={{ base: "1vw", md: "1vw" }}>
                Reserva una cita en mi calendario y lo charlamos con un café.
                ¡Ser un placer charlar un rato juntos! 🤘
              </Text>
              
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0DY58it5Za1mRVsqLcRhps7HzQp4dHYQvwRa7UL7pWmoX_TIVibaswTk2PmTN0ak5axMtZItkz"
              >
                <Button
                  marginTop={{ base: "7vw", md: "1.5vw" }}
                  className="bookme"
                >
                  Reserva
                </Button>
              </a>
            </CardBody>
          </Stack>
        </Card>
       
      </VStack>
    </SimpleGrid>
    
    </Fragment>
   
  );
}
