import { Heading, Box, Text, SimpleGrid, Button } from "@chakra-ui/react";
import { Fragment } from "react";

export function MoreInformation() {
  return (
    <Fragment>
      <Box className="card-more-info" borderRadius="6" boxShadow={"xl"}>
        <SimpleGrid
          columns={{ base: 1, md: 1 }}
          gap="15px"
          marginLeft={{ base: "5%", md: "5%" }}
          marginRight={{ base: "5%", md: "5%" }}
          marginTop={{ base: "10%", md: "5%" }}
        >
          <Heading
            className="information-heading"
            marginBottom={{ base: "1%", md: "1%" }}
          >
            Necesitas más información sobre el curso?
          </Heading>
          <Text>
            Déjame tus datos y te mandaré todos los detalles y el dossier de la
            formación. <b>Deja de contratar como en 2010</b> y descubre los
            secretos para contratar talento tecnológico sin complicaciones.
          </Text>
          <Text>
            <b>👉 Próxima edición: Mayo 2025</b>
          </Text>

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap="15px"
            marginTop={{ base: "1%", md: "1%" }}
            marginBottom={{ base: "10%", md: "5%" }}
          >
            <a href="https://buy.stripe.com/28o3g7emNd5r2Fq5kk" target="_blank" rel="noreferrer">
              <Button width={"100%"}>Reservar mi plaza</Button>
            </a>
            <Button colorScheme="pink" onClick={window['show_MoreInformation_Popup']}>
              Quiero más información
            </Button>
          </SimpleGrid>
        </SimpleGrid>
      </Box>
    </Fragment>
  );
}
