import { Heading, Box, Text, Stack, SimpleGrid } from "@chakra-ui/react";
import { MoreInformation } from "./academy-more-information";
import { IndividualFormat } from "./academy-individual-format";
import { IndividualFormatWeeks } from "./academy-individual-format-weeks";

export function AcademyFormats() {
  return (
    <Box className="my-history-box">
      &nbsp;
      <Heading
        className="what-i-do-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "8vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        El formato<span style={{ color: "#C9E500" }}>.</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "5vh", md: "10vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <Text>
            Es un taller pensado y diseñado para ser muy práctico, con sesiones
            dinámicas en las que los alumnos se llevarán acciones concretas para
            aplicar desde el día siguiente.{" "}
            <b>
              El curso está estructurado en 4 sesiones de 2 horas y media, que
              se impartirán los primeros 4 lunes del mes, de 16:00 a 18:30
            </b>
            , para que puedas compatibilizarlo fácilmente con tu trabajo.{" "}
          </Text>
        </Stack>
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "20vh", md: "27vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <IndividualFormat />
        <IndividualFormatWeeks />
        <MoreInformation />
      </SimpleGrid>
    </Box>
  );
}
