import './App.css';
import { Fragment, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { Landing } from './pages/landing';
import { Academy } from './pages/academy';

import ReactGA from 'react-ga4';
import { Blog } from './pages/blog';
import { NextEdition } from './pages/next-edition';

ReactGA.initialize("G-8W7QX2476E");

export default function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
  }, []);

  return (
    <Fragment>
       <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/recruiting-academy" element={<Academy />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/next-edition" element={<NextEdition />} />
      </Routes>
    </Fragment>  
  );
}